import { useEffect, useRef, useState } from 'react';
import { TjenesteOverviewDto, TjenesteSearchFiter } from "services/AdminportalenApi/TjenesterApi/types";
import TjenesterApi from 'services/AdminportalenApi/TjenesterApi';
import { Button, Col, Form, Row, Table, InputGroup } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import CreateTjeneste from './components/CreateTjeneste';
import querystring from 'query-string';
import Style from './index.module.css';
import TjenestegruppeSelect from 'components/TjenestegruppeSelect';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';
import formatAsCurrency from 'utils/formatAsCurrency';
import CommonLoader from 'components/CommonLoader';

const TjenesteSok = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [overviewTjenester, setOverviewTjenester] = useState<TjenesteOverviewDto[]>([]);
	const [showCreateTjeneste, setShowCreateTjeneste] = useState<boolean>(false);
	const [filter, setFilter] = useState<TjenesteSearchFiter>({
		query: '',
	});
	const isMountedRef = useIsMountedRef();
	const firstRender = useRef<boolean>(true);

	const loadTjenesterOverview = async () => {
		setIsLoading(true);
		const result = await TjenesterApi.getOverview();
		setOverviewTjenester(result);
		setIsLoading(false);
	};

	const goToTjenesteDetails = (tjenesteId: string) => {
		navigate(`/Tjenester/${tjenesteId}/Details`);
	}
	const handleFilter = () => {
		let parsedFilter = null;
		if (location.search && location.search.length > 0) {
			parsedFilter = querystring.parse(
				location.search.substring(1, location.search.length),
				{ parseNumbers: true, parseBooleans: true, arrayFormat: 'bracket' }
			);
			let query = parsedFilter?.query?.toString();

		}
		let tjenestegruppeId = parsedFilter?.tjenestegruppeId?.toString() ?? null;
		setFilter(prev => ({
			...prev,
			query: parsedFilter?.query?.toString() || '',
			tjenestegruppeId,

		}))
	}
	useEffect(() => {
		window.addEventListener("beforeunload", e => { handleFilter(); });
		return () => {
			window.removeEventListener("beforeunload", e => handleFilter());
		};
	}, []);

	useEffect(() => {
		if (firstRender.current) {
			handleFilter();
			firstRender.current = false;
		}
	}, []);



	useEffect(() => {
		if (isMountedRef.current && !firstRender.current) {
			let qs = '?';
			if (filter.query) {
				qs = `${qs}query=${filter.query}`;
			}

			if (filter.tjenestegruppeId) {
				qs = `${qs}&tjenestegruppeId=${filter.tjenestegruppeId}`;
			}

			navigate({
				pathname: '/Tjenester',
				search: qs
			})
		}
	}, [filter]);



	useEffect(() => {
		loadTjenesterOverview();
	}, []);

	const filteredTjenester = overviewTjenester.filter(x =>
		!filter.query || filter.query === '' ?
			x :
			x.tjenesteGruppeNavn.toLowerCase().includes(filter.query.toLowerCase()) ||
			x.tjenesteNavn.toLowerCase().includes(filter.query.toLowerCase()) || 
			x.tjenesteId.toLowerCase().includes(filter.query.toLowerCase())
	).filter(x => !filter.tjenestegruppeId ?
		x :
		x.tjenesteGruppeId === filter.tjenestegruppeId
	);

	return (

		<div className={Style.container}>
			<Row style={{ marginTop: '0.5em' }}>
				<Col xs='8'>
					<h2 className={Style.mainHeader}>Tjenester</h2>
				</Col>

				<Col>
					<div className='float-end'>
						<CreateTjeneste
							show={showCreateTjeneste}
							setShow={setShowCreateTjeneste}
							onUpdated={id => goToTjenesteDetails(id)}
						/>
					</div>
				</Col>
			</Row>

			<Row>
				<Col>
					<InputGroup className="mb-3">
						<Form.Control placeholder='Søk tjenester'
							type='text'
							value={filter.query}
							style={{ borderRight: 'hidden', borderRadius: '24px 0px 0px 24px' }}
							onChange={e => setFilter(prev => ({ ...prev, query: e.target.value }))} />
						<InputGroup.Text style={{ borderLeft: 'hidden', borderRadius: '0px 24px 24px 0px' }}>
							{filter.query.length > 0 ? (
								<span onClick={() => setFilter(prev => ({ ...prev, query: '' }))}>
									<IcebergIcon icon='close' cursor='pointer' />
								</span>
							) : (
								<IcebergIcon icon='search' />
							)}
						</InputGroup.Text>
					</InputGroup>
				</Col>



				<Col>
					<span style={{ marginTop: '5px', color: '#68778D', float: 'right' }}>Viser {filteredTjenester.length} av {overviewTjenester.length} tjenester</span>
				</Col>

				<Col className='float-end'>
					<TjenestegruppeSelect selected={filter.tjenestegruppeId || ''} onChange={e => setFilter(prev => ({ ...prev, tjenestegruppeId: e ?? null }))} />
				</Col>


			</Row>

			{/* Make a layout for lists of tjenester  */}
			<Row>
				<Col>
					{/* feil håndtering hvis data ikke kommer */}
					{
						isLoading && (overviewTjenester.length <= 0) &&
						<Row>
							<Col>
								<CommonLoader text="Henter tjenester..." color="#003b6e" />
							</Col>
						</Row>
					}
					{!isLoading && (overviewTjenester.length <= 0) && (
						<div>
							<h3>Ingen treff</h3>
						</div>
					)}

					{(overviewTjenester.length > 0) && (
						<Row className={Style.wrapper}>
							<Table responsive hover>
								<thead>
									<tr>
										<th>Tjenestegruppe</th>
										<th>Tjeneste</th>
										<th>Pogo nummer</th>
										<th style={{ textAlign: 'end', width: "88px" }}>Pris (NOK) </th>
										<th></th>
									</tr>
								</thead>
								<tbody className={Style.tbody} style={{ borderTop: 'none', border: '1px solid #DCDCDC' }}>
									{filteredTjenester.map(t => (
										<tr key={t.tjenesteId}>
											<td>{t.tjenesteGruppeNavn}</td>
											<td>{t.tjenesteNavn}</td>
											<td>{t.pogonummer}</td>
											<td className='fitContent'>{formatAsCurrency(t.pris)}</td>
											<td className='fitContent'>
												<Button onClick={() => goToTjenesteDetails(t.tjenesteId)}
													variant='outline-primary' size='sm'>
													Vis detaljer
												</Button>
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						</Row>
					)}
				</Col>
			</Row>
		</div>
	);
}


export default TjenesteSok;