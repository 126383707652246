import { useEffect, useState } from "react";
import { IconButton } from "@tradesolution/iceberg-ui-react";
import { Alert, Card, Form, Modal, Row, Spinner, Table } from "react-bootstrap";
import FakturagrunnlagApi from "services/AdminportalenApi/FakturagrunnlagApi";
import { GenererFakturagrunnlagForAbonnementCommand, GenererFakturagrunnlagForAbonnementResponse, TjenestegruppeDto, UtestaaendeFakturaPeriode } from "services/AdminportalenApi/FakturagrunnlagApi/types";
import useLocalStorage from "utils/hooks/useLocalStorage";
import IcebergAlert from "components/IcebergAlert";
import PeriodeVelger from "./PeriodeVelger";
import { DatePeriod } from "./DatePeriod";
import { PeriodeVelgerData } from "./PeriodeVelgerData";
import { DatePeriodHelper } from "./DatePeriodHelper";
import CommonLoader from "components/CommonLoader";
import TsKundeTypeahead from "components/TsKundeTypeahead";
import { TsKundeSearchDto } from "services/AdminportalenApi/TsKunderApi/types";

interface Props {
    show: boolean;
    onModalClose: () => void;
    showDebugInfo?: boolean;
}

const UtestaaendeModal = (props: Props) => {

    const [selectedTjenesteGruppeId, setSelectedTjenesteGruppeId] = useLocalStorage<string | undefined>("fakturagrunnlag_selectedTjenesteGruppeId", undefined);
    const [selectedTjenesteGruppeDtos, setSelectedTjenesteGruppeDtos] = useState<TjenestegruppeDto[]>([]);

    const handleTjenesteGruppeSelected = (tjenesteGruppeId: string) => {
        setSelectedTjenesteGruppeId(tjenesteGruppeId);
        setMonth(undefined);
        setQuarter(undefined);
    };

    const [tjenestegrupper, setTjenestegrupper] = useState<TjenestegruppeDto[]>([]);

    const [isGenerating, setIsGenerating] = useState(false);

    const [year, setYear] = useState(new Date().getFullYear());
    const [quarter, setQuarter] = useState<number | undefined>();
    const [month, setMonth] = useState<number | undefined>();

    const [result, setResult] = useState<UtestaaendeFakturaPeriode[]>([]);
    const [loading, setLoading] = useState(false);

    const [periodeVelgerData, setPeriodeVelgerData] = useState<PeriodeVelgerData | undefined>();

    const [selectedTsKunde, setSelectedTsKunde] = useState<TsKundeSearchDto | undefined>();
    const [selectedTjenesteIds, setSelectedTjenesteIds] = useState<string[]>([]);
    const [checkAll, setCheckAll] = useState<boolean>(false);
    
    const handleCheckAll = (on: boolean) => {
		setCheckAll(on);
		if (on) {
			setSelectedTjenesteIds(result.map((x) => x.tjenesteId));
		} else {
			setSelectedTjenesteIds([]);
		}
	};

    const loadUtestaaende = async () => {
        setLoading(true);
        const r = await FakturagrunnlagApi.utestaaendeFakturaResult(selectedTjenesteGruppeId, year, quarter, month, selectedTsKunde?.tsKundeId);
        setLoading(false);
        setResult(r);
    };

    const loadTjenestegrupperRelevantForFakturagrunnlag = async () => {
        const r = await FakturagrunnlagApi.tjenestegrupperRelevantForFakturagrunnlag();
        setTjenestegrupper(r);
    }

    useEffect(() => {
        loadTjenestegrupperRelevantForFakturagrunnlag();
    }, []);

    useEffect(() => {
        if (selectedTjenesteGruppeId && tjenestegrupper.length > 0) {

            const tjenester = tjenestegrupper.filter(o => o.tjenesteGruppeId === selectedTjenesteGruppeId);
            setSelectedTjenesteGruppeDtos(tjenester);

            const periodeData = PeriodeVelgerData.create(tjenester);
            setPeriodeVelgerData(periodeData);

            // reset dates
            setYear(new Date().getFullYear());

            if (periodeData.faktureringsInterval === 1) {
                setQuarter(undefined);
                setMonth(new Date().getMonth());
            }
            else if (periodeData.faktureringsInterval === 3) {
                setQuarter(DatePeriodHelper.getCurrentQuarter().quarter);
                setMonth(undefined);
            }

            // const defaultPeriod = PeriodeVelgerData.getPeriod(periodeData.faktureringsType, periodeData.faktureringsInterval);

            // setPeriodeFra(defaultPeriod.fraOgMed);
            // setPeriodeTil(defaultPeriod.tilOgMed);
        }
    }, [selectedTjenesteGruppeId, tjenestegrupper]);

    useEffect(() => {
        if (selectedTjenesteGruppeId && year && quarter) {
            console.log("load quarter")
            loadUtestaaende();
        }
    }, [selectedTjenesteGruppeId, year, quarter, selectedTsKunde]);

    useEffect(() => {
        if (selectedTjenesteGruppeId && year && month != undefined) {
            console.log("load month")
            loadUtestaaende();
        }
    }, [selectedTjenesteGruppeId, year, month, selectedTsKunde]);

    const [genererResponse, setGenereResponse] = useState<GenererFakturagrunnlagForAbonnementResponse | undefined>(undefined);


    const handleStartGenerering = async () => {
        const command: GenererFakturagrunnlagForAbonnementCommand = {
            tjenesteGruppeId: selectedTjenesteGruppeId,
            year: year,
            quarter: quarter,
            month: month + 1, // 0 index i javascript,
            tsKundeIds: selectedTsKunde ? [selectedTsKunde.tsKundeId] : undefined,
            tjenesteIds: selectedTjenesteIds.length > 0 ? selectedTjenesteIds : undefined
        };

        setIsGenerating(true);

        try {
            const gr = await FakturagrunnlagApi.genererFakturagrunnlagForAbonnement(command);
            setGenereResponse(gr);
        }
        catch (error) {
            console.error(error);
        }

        setIsGenerating(false);
    };

    const submitDisabled = loading || isGenerating || !selectedTjenesteGruppeId;

    const handleClose = () => {
        setGenereResponse(undefined);
        props.onModalClose();
    };

    const getUniqueTjenesteGrupper = () => {
        const uniqueIds = Array.from(new Set(tjenestegrupper.map(o => o.tjenesteGruppeId)));
        return uniqueIds.map(o => ({
            tjenesteGruppeId: o,
            tjenesteGruppeNavn: tjenestegrupper.find(i => i.tjenesteGruppeId == o).tjenesteGruppeNavn
        }));
    }

    const handlePeriodeChanged = (period: DatePeriod): void => {
        setYear(period.year);
        setQuarter(period.quarter);
        setMonth(period.month);
    }

    const handleTsKundeSelected = (selectedKunde: TsKundeSearchDto): void => {
        setSelectedTsKunde(selectedKunde);
    }

    return (<Modal size="xl" show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Opprett fakturagrunnlag</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Row>
                    <Form.Group>
                        <Form.Label>Tjenestegruppe</Form.Label>
                        <Form.Select
                            onChange={e => handleTjenesteGruppeSelected(e.target.value)}
                            value={selectedTjenesteGruppeId}
                            style={{ borderRadius: "24px" }}
                        >
                            <option value="">Velg tjenestegruppe</option>
                            {
                                getUniqueTjenesteGrupper().map(x =>
                                    <option key={x.tjenesteGruppeId} value={x.tjenesteGruppeId}>{x.tjenesteGruppeNavn}</option>
                                )
                            }
                        </Form.Select>
                    </Form.Group>
                </Row>
                {props.showDebugInfo && <Row className="mt-3">
                    <Table>
                        <tbody>
                            {selectedTjenesteGruppeDtos.map(o => <tr>
                                <td>{o.faktureresForskuddsvis ? "Forskudd" : "Etterskudd"}</td>
                                <td>{o.faktureringsIntervall}</td>
                            </tr>)}
                        </tbody>
                    </Table>
                </Row>}
                {periodeVelgerData && <Row className="mt-3">
                    <PeriodeVelger
                        faktureringsInterval={periodeVelgerData.faktureringsInterval}
                        year={year}
                        quarter={quarter}
                        month={month}
                        onPeriodeChanged={handlePeriodeChanged}
                    />
                </Row>}
                <Row className="mt-3">
                    <Form.Group>
                        <Form.Label>Generer kun for valgt TsKunde</Form.Label>
                        <TsKundeTypeahead onHandleChange={handleTsKundeSelected} selectedTsKundeId={selectedTsKunde?.tsKundeId} />
                    </Form.Group>
                </Row>
            </Form>
            {isGenerating &&
                <div className="mt-2">
                    <IcebergAlert variant="info" icon="info">
                        <Spinner animation="border" size="sm" style={{ marginRight: "0.5rem" }} />
                        Genererer fakturagrunnlag. Dette kan ta litt tid...
                    </IcebergAlert>
                </div>
            }
            {!!genererResponse &&
                <Alert className="mt-3" variant="success">
                    Fakturagrunnlag oppdatert. Lukk modalen for å se resultatet.
                </Alert>
            }
            {!loading && result && result.length === 0 &&
                <Alert className="mt-3" variant="info">
                    Fant ingen tjenester
                </Alert>
            }
            {loading && <div className="mt-2 d-flex align-items-center justify-content-center" style={{ height: "300px" }}>
                <div className="text-center">
                    <CommonLoader color="#003b6e" />
                </div>
            </div>}
            {!loading && result && result.length > 0 && <Card className="mt-3">
                <Card.Body>
                    <div className="table-responsive">
                        <Table>
                            <thead>
                                <tr>
                                    <th className="fitContent">
                                        <Form.Check
                                            checked={checkAll}
                                            onChange={() => handleCheckAll(!checkAll)}
                                        />
                                    </th>
                                    <th>Tjeneste</th>
                                    <th>Gjelder</th>
                                    <th>Type</th>
                                    <th>Ant. abonnement</th>
                                </tr>
                            </thead>
                            <tbody>
                                {result.map(t => <tr key={`${t.tjenesteId}_${t.gjelderTekst}`}>
                                    <td className="fitContent">
                                        <Form.Check
                                            checked={selectedTjenesteIds.includes(
                                                t.tjenesteId
                                            )}
                                            onChange={(e) => {
                                                if (
                                                    selectedTjenesteIds.includes(
                                                        t.tjenesteId
                                                    )
                                                ) {
                                                    setSelectedTjenesteIds(
                                                        selectedTjenesteIds.filter(
                                                            (x) => x !== t.tjenesteId
                                                        )
                                                    );
                                                } else {
                                                    setSelectedTjenesteIds(
                                                        selectedTjenesteIds.concat(
                                                            t.tjenesteId
                                                        )
                                                    );
                                                }
                                            }}
                                        />
                                    </td>
                                    <td>{t.tjenesteNavn}</td>
                                    <td>{t.gjelderTekst}</td>
                                    <td>{t.faktureresForskuddsvis ? "Forskudd" : "Etterskudd"}</td>
                                    <td className="text-center">{t.antallAktiveAbonnement}</td>
                                </tr>)}
                            </tbody>
                        </Table>
                        <Form.Label>Velg de tjenestene du ønsker å fakturere. Velger du ingen får du alle</Form.Label>
                    </div>
                </Card.Body>
            </Card>}
        </Modal.Body>
        <Modal.Footer>
            {!!genererResponse && <IconButton icon="close" className="col" variant="outline-primary" onClick={handleClose}>Lukk</IconButton>}
            {!genererResponse && <>
                <IconButton icon="close" className="col" variant="outline-primary" onClick={handleClose}>Lukk</IconButton>
                <IconButton disabled={submitDisabled} icon="chevron-right" className="col" variant="primary" onClick={handleStartGenerering}>Start</IconButton>
            </>}
        </Modal.Footer>
    </Modal>);
};

export default UtestaaendeModal;