import { IcebergAlert, IcebergAlertVariant, IconButton } from "@tradesolution/iceberg-ui-react";
import { Modal, Form } from "react-bootstrap";
import AdApplicationsApi from "services/AdminportalenApi/AdApplicationsApi";
import { AdApplication } from "services/AdminportalenApi/AdApplicationsApi/types";

interface Props {
	application?: AdApplication;
	show: boolean;
	onClose: () => void;
}

const DeleteModal = (props: Props) => {

	const deleteApp = async (app: AdApplication) => {
		await AdApplicationsApi.delete(app.applicationId);
		props.onClose();
	};

	return (
		<Modal show={props.show} onHide={props.onClose} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>
					Slette {props.application?.name}?
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<IcebergAlert variant={IcebergAlertVariant.Warning} body=
						'Kan bare gjøres av UberAdmin. Sletter også i Azure og KeyVault' />
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<IconButton variant="outline-primary" icon="close" className="col" onClick={props.onClose}>
					Lukk
				</IconButton>
				<IconButton variant="danger" size="sm" icon="trash" className="col" onClick={() => deleteApp(props.application)}>
					Slett
				</IconButton>
			</Modal.Footer>
		</Modal >
	);
};

export default DeleteModal;