import { Modal, Row, Col, Table } from 'react-bootstrap';
import { IconButton } from '@tradesolution/iceberg-ui-react';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import CommonLoader from 'components/CommonLoader';
import PermissionsChangeLogApi from 'services/AdminportalenApi/PermissionsChangeLogApi';
import { PermissionsChangeLogDto } from 'services/AdminportalenApi/PermissionsChangeLogApi/types';
import { AdApplication } from 'services/AdminportalenApi/AdApplicationsApi/types';

interface Props {
  app: AdApplication;
  show: boolean;
  setShow: (value: boolean) => void;
}

const PermissionsChangeLogModal = (props: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [history, setHistory] = useState<PermissionsChangeLogDto[]>([]);


  const loadHistory = async () => {
    setIsLoading(true);
    let history = await PermissionsChangeLogApi.getPermissionsChangeLog(props.app.objectId);
    setHistory(history);
    setIsLoading(false);
  };

  useEffect(() => {
    loadHistory();
  }, [props.app])


  return (
    <Modal size="xl" centered show={props.show} onHide={() => props.setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Tilgangsendringer for {props.app.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading && (
          <Row>
            <Col>
              <CommonLoader text="Henter nyeste tilgangsendringer..." color="#003b6e" />
            </Col>
          </Row>
        )}
        {!isLoading && (
          <Row>
            <Col>
              {history.length === 0 && (
                <span>Ingen endringer registrert</span>
              )}
              {history.length > 0 && (
                <Table responsive style={{ borderCollapse: 'collapse', borderTop: 'none' }} hover>
                  <thead>
                    <tr>
                      <th>Tidspunkt</th>
                      <th>Endret av</th>
                      <th>Status</th>
                      <th>Detaljer</th>
                    </tr>
                  </thead>
                  <tbody style={{ borderTop: 'none', border: '1px solid #DCDCDC' }}>
                    {
                      history.map((t, index) =>
                        <tr key={index}>
                          <td>{format(new Date(t.changedAt), 'dd.MM.yyyy HH:mm')}</td>
                          <td>{t.changedBy}</td>
                          <td>{t.permissionsAdded?.length > 0 ? 'Lagt til' : 'Fjernet'}</td>
                          <td>
                          {t.permissionsAdded?.length > 0 && (
                             <div>
                              {t.permissionsAdded.map(x => 
                                <div>{x.tsKundeNavn} - {x.accessToDisplayName} {x.roleDisplayName ? ' (' + x.roleDisplayName + ')': ''}</div>
                                )}                              
                             </div> 
                          )}
                          {t.permissionsRemoved?.length > 0 && (
                             <div >
                              {t.permissionsRemoved.map(x => 
                                <div>{x.tsKundeNavn} - {x.accessToDisplayName} {x.roleDisplayName ? ' (' + x.roleDisplayName + ')': ''}</div>
                                )}                              
                             </div> 
                          )}
                          </td>
                        </tr>                        
                      )
                    }
                  </tbody>
                </Table>
              )}
            </Col>
          </Row>
        )}

      </Modal.Body>
      <Modal.Footer>
        <IconButton icon="close" className='col' variant="outline-primary" onClick={() => props.setShow(false)}>
          Lukk
        </IconButton>
      </Modal.Footer>
    </Modal>
  )
}

export default PermissionsChangeLogModal;